import {Component, Input, OnInit} from '@angular/core';
import {ServerError} from '../../models/server-error.model';

@Component({
    selector: 'app-server-error-list',
    templateUrl: './server-error-list.component.html',
    styleUrls: ['./server-error-list.component.scss']
})
export class ServerErrorListComponent implements OnInit {
    @Input() serverErrors: ServerError[] = [];

    constructor() {
    }

    ngOnInit(): void {
    }

}
