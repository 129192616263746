import {Component, EventEmitter, inject, Inject, OnInit, Output} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {AuthService} from "../../../core/services/auth.service";
import {ProfileStateService} from "../../../core/states/profile-state.service";
import {CoreConfig} from "../../../core/core.config";
import Swal from "sweetalert2";
import {ProfileService} from "../../../core/services/profile.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UserProfileEditModalComponent} from "../../../shared/modals/user-profile-edit-modal.component";
import {from} from "rxjs";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  authService =  inject(AuthService);
  coreConfig = inject(CoreConfig);
  profileStateService = inject(ProfileStateService);
  modalService = inject(NgbModal);
  @Output() sidebarToggled = new EventEmitter<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  ngOnInit(): void {
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.sidebarToggled.emit();
  }

  /**
   * Logout
   */
  onLogout(e: Event) {
    e.preventDefault();
    this.authService.logout().subscribe(() => window.location.assign('/'));
  }

  showAnnouncement() {
    Swal.fire({
      title: 'Announcement',
      text: this.coreConfig.dominoConfig.whitelabel.topbarannouncement,
      icon: 'info',
      customClass: {
        htmlContainer: 'text-start'
      },
      confirmButtonText: 'OK'
    });
  }

  openProfilModal() {
    const modal = this.modalService.open(UserProfileEditModalComponent, {size: 'lg', backdrop: 'static'});
    return from(modal.result).subscribe();
  }
}
