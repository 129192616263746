<div class="main-wrapper">

  <!-- partial:sidebar -->
  <app-sidebar [sidebarToggled]="sidebarToggle$"></app-sidebar>
  <!-- partial -->

  <div class="page-wrapper">

    <!-- partial:navbar -->
    <app-navbar (sidebarToggled)="sidebarToggled()"></app-navbar>
    <!-- partial -->

    <div class="page-content" [class.no-padding]="noPadding">

      <!-- Spinner for lazyload modules -->
      <div *ngIf="isLoading" class="spinner-wrapper">
        <div class="spinner">Loading...</div>
      </div>

      <ng-container *ngIf="!isLoading">
        <router-outlet></router-outlet>
      </ng-container>

    </div>

    <!-- partial:footer -->
    <app-footer></app-footer>
    <!-- partial -->

  </div>


</div>
