import {computed, Injectable, signal} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {Profile} from '../models/profile.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileStateService {

  public profile$ = new ReplaySubject<Profile | null>(1);

  private _profileSignal = signal<Profile | null>(null)
  public profile = this._profileSignal.asReadonly();
  public isLoggedIn = computed(() => this._profileSignal() !== null);

  public startOverviews = [
    {value: '', label: 'Systemstandard'},
    {value: 'search', label: 'Dokumenter'},
    {value: 'collection', label: 'Samlinger'},
    {value: 'favorites', label: 'Favoritter'},
    {value: 'subscription', label: 'Abonnementer'},
  ];
  public startCollectionLevels = [
    {value: '', label: 'Systemstandard'},
    {value: 'collectionall', label: 'Alle samlinger'},
    {value: 'collectionniv1', label: 'Regionale samlinger'},
    {value: 'collectionniv2', label: 'Min enheds samlinger'},
    {value: 'collectionniv3', label: 'Min afdelings samlinger'},
  ]

  constructor() {
  }

  public updateProfile(profile: Profile | null) {
    this.profile$.next(profile);
    this._profileSignal.set(profile);

  }


}
