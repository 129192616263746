import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProfileService} from "../services/profile.service";

export const redirectToStartpageBasedOnProfileGuard: CanActivateFn = (): Observable<boolean> => {
  const router = inject(Router);
  const profileService = inject(ProfileService);

  return profileService.getStartPageRedirectUrl().pipe(
    map(url => {
      router.navigateByUrl(router.parseUrl(url));
      return false;
    })
  );
};

