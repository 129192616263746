import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProfileService} from "../services/profile.service";
import {ProfileStateService} from "../states/profile-state.service";
export const requireLoginGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const profileStateService = inject(ProfileStateService);
  const router = inject(Router);

  return profileStateService.profile$.pipe(
    map((profile) => {
      return profile !== null ? true : router.parseUrl('auth/login?returnUrl=' + encodeURIComponent(state.url.substring(1)));
    })
  );
};
