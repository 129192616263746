import {Injectable} from '@angular/core';
import {Observable, zip} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {CoreConfig} from '../core.config';
import {Organisation} from '../models/organisation.model';
import {handleHttpError} from '../utilities/error-handling/handle-http-error.pipe$';

@Injectable({
  providedIn: 'root'
})
export class OrganisationResource {

  constructor(
    public httpClient: HttpClient,
    public coreConfig: CoreConfig,
  ) {
  }

  public getOrganisationsAll(): Observable<Organisation[]> {
    return zip(
      this.httpClient.get<{
        success: boolean,
        data: { name: string, id: string }[]
      }>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.portaldatabase}/GetOrgAll?OpenAgent&name=level`),
      this.httpClient.get<{
        success: boolean,
        data: { name: string, id: string }[]
      }>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.portaldatabase}/GetOrgAll?OpenAgent&name=publevel`)).pipe(
      handleHttpError(),
      map(([orgs, pubOrgs]) => {
        const organisations = orgs.data
          .map(org => this.mapToOrganisationModel(org));

        const publisherOrganisations = pubOrgs.data
          .map(org => this.mapToOrganisationModel(org));

        organisations.map(org => {
          if (publisherOrganisations.find(publisherOrg => publisherOrg.id === org.id)) {
            org.publisher = true;
          }
          return org;
        });

        return organisations;
      }));
  }

  public getOrganisationsPublishing(): Observable<Organisation[]> {
    return this.httpClient.get<{
      success: boolean,
      data: { name: string, id: string }[]
    }>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.portaldatabase}/GetOrgAll?OpenAgent&name=publevel`).pipe(
      handleHttpError(),
      map(response => response.data
        .filter(org => this.isValidOrganisation(org))
        .map(org => this.mapToOrganisationModel(org))
        .map(org => {
          org.publisher = true;
          return org;
        }))
    );
  }

  public getOrganisations(): Observable<Organisation[]> {
    return this.httpClient.get<{
      success: boolean,
      data: { name: string, id: string }[]
    }>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.portaldatabase}/GetOrgAll?OpenAgent&name=level`).pipe(
      handleHttpError(),
      map(response => response.data
        .filter(org => this.isValidOrganisation(org))
        .map(org => this.mapToOrganisationModel(org))
      )
    );
  }

  private mapToOrganisationModel(org: any): Organisation {
    let parentId = null;
    let level = 1;
    if (org.id.length === 6) {
      parentId = org.id.substring(0, 4);
      level = 2;
    }
    if (org.id.length > 6) {
      parentId = org.id.substring(0, 6);
      level = 3;
    }
    return {
      id: org.id,
      name: org.name,
      parentId: parentId,
      level: level,
      publisher: false,
    } as Organisation;
  }

  private isValidOrganisation(org: { name: string, id: string }) {
    const blacklist = ['- Alle -'];
    return  ! blacklist.includes(org.id);
  }
}
