import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CoreConfig} from '../core.config';
import {map} from "rxjs/operators";
import {
  DominoArrayResponse,
  DominoAttachmentListItem, DominoFavorite,
  DominoResponse, DominoSubscriptionAttachment, DominoSubscriptionCollection,
  DominoSubscriptionDocument, DominoSubscriptionPublisher
} from "../models/domino-response.model";
import {Attachment} from "../models/attachment.model";
import {Observable} from "rxjs";
import {
  Favorite,
  SubscriptionAttachment,
  SubscriptionCollection,
  SubscriptionDocument,
  SubscriptionPublisher
} from "../models/document.model";

@Injectable({
  providedIn: 'root'
})
export class FavoriteResource {
  public httpClient = inject(HttpClient);
  public coreConfig = inject(CoreConfig);


  getFavorites(): Observable<Favorite[]> {
    return this.httpClient.get<DominoResponse<DominoFavorite[]>>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.favoriteDatabase}/GetUserFavorites?OpenAgent`, {
      withCredentials: true
    }).pipe(
      map(response => response.data),
      map(favorites => favorites.map(favorite => {
        const length = favorite.publisherid?.length ?? 0;
        const level = length === 4 ? 1 : length === 6 ? 2 : length === 8 ? 3 : 0;
        return {
          id: favorite.id,
          title: favorite.title,
          level: level,
          doctype: favorite.doctype,
          publisher: favorite.publisher,
          publisherId: favorite.publisherid,
          createdAt: favorite.created.substring(0,10),
          type: favorite.plejelaegelig ?? null,
        } as Favorite;
      })),
    )
  }

  createFavoriteDocument(id: string) {
    return this.endpoint(id, 'document', 'create');
  }

  removeFavoriteDocument(id: string) {
    return this.endpoint(id, 'document', 'remove');
  }

  createFavoriteCollection(id: string) {
    return this.endpoint(id, 'collection', 'create');
  }

  removeFavoriteCollection(id: string) {
    return this.endpoint(id, 'collection', 'remove');
  }

  endpoint(id: string, type: "document"|"collection", action: "create"|"remove") {
    return this.httpClient.get<DominoResponse<DominoFavorite>>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.favoriteDatabase}/updateUserFavorites?OpenAgent&doctype=${type}&action=${action}&id=${id}`, {
      withCredentials: true
    }).pipe(
      map(response => {
        if ( ! response.success) {
          throw new Error('API Error: ' + (response as any)?.msg);
        }
        return response.success;
      }),
    )
  }
  getSubscriptionsDocuments(): Observable<SubscriptionDocument[]> {
    return this.getSubscriptions<DominoSubscriptionDocument>('document').pipe(
      map(subscriptions => subscriptions.map(subscription => {
        const length = subscription.publisherid?.length ?? 0;
        const level = length === 4 ? 1 : length === 6 ? 2 : length === 8 ? 3 : 0;

        return {
          id: subscription.docid,
          title: subscription.title,
          level: level,
          publisher: subscription.publisherdisplay,
          publisherId: subscription.publisherid,
          type: subscription.plejelaegelig
        } as SubscriptionDocument;
      })),
    );
  }

  getSubscriptionsCollections(): Observable<SubscriptionCollection[]> {
    return this.getSubscriptions<DominoSubscriptionCollection>('collection').pipe(
      map(subscriptions => subscriptions.map(subscription => {
        const length = subscription.publisherid?.length ?? 0;
        const level = length === 4 ? 1 : length === 6 ? 2 : length === 8 ? 3 : 0;

        return {
          id: subscription.docid,
          title: subscription.title,
          level: level,
          publisher: subscription.publisherdisplay,
          publisherId: subscription.publisherid,
        } as SubscriptionCollection;
      })),
    );
  }


  subscriptionEndpoint(id: string, type: string, action: string) {
    return this.httpClient.get<DominoResponse<any>>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.subscriptionDatabase}/updateUserSubscriptions?OpenAgent&doctype=${type}&action=${action}&id=${id}`, {
      withCredentials: true
    }).pipe(
      map(response => {
        if ( ! response.success) {
          throw new Error('API Error: ' + (response as any)?.msg);
        }
        return response.success;
      }),
    )
  }

  getSubscriptions<T>(type: 'document'|'collection'): Observable<T[]> {
    return this.httpClient.get<DominoResponse<T[]>>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.subscriptionDatabase}/GetSubscriptions?OpenAgent&action=${type}`, {
      withCredentials: true
    }).pipe(
      map(response => response.data),
    )
  }


}
