import {inject, Injectable, signal} from '@angular/core';
import {SubscriptionResource} from "../resources/subscription-resource";
import {merge, Observable, of, tap} from "rxjs";
import {
  SubscriptionAttachment,
  SubscriptionCollection,
  SubscriptionDocument,
  SubscriptionPublisher
} from "../models/document.model";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private subscriptionResource = inject(SubscriptionResource);
  public attachments = signal<SubscriptionAttachment[]>([]);
  public documents = signal<SubscriptionDocument[]>([]);
  public collections = signal<SubscriptionCollection[]>([]);
  public publishers = signal<SubscriptionPublisher[]>([]);
  private isInitiated = false;

  public initialLoad(force: boolean = false) {
    if (this.isInitiated && !force) {
      return;
    }
    merge(
      this.getSubscriptionsAttachments(),
      this.getSubscriptionsCollections(),
      this.getSubscriptionsDocuments(),
      this.getSubscriptionsPublishers(),
    ).subscribe();

    this.isInitiated = true;
  }

  public toggleSubscriptionAttachment(id: string) {
    this.toggleSubscription(id, 'attachment');
  }

  public toggleSubscriptionCollection(id: string) {
    this.toggleSubscription(id, 'collection');
  }

  public toggleSubscriptionDocument(id: string) {
    this.toggleSubscription(id, 'document');
  }

  public toggleSubscriptionPublisher(id: string) {
    this.toggleSubscription(id, 'publisher');
  }

  public isSubscribed(id: string): boolean {
    // check all types signals  of subscriptions
    return this.attachments().some(attachment => attachment.id === id)
      || this.documents().some(document => document.id === id)
      || this.collections().some(collection => collection.id === id)
      || this.publishers().some(publisher => publisher.id === id);
  }

  private toggleSubscription(id: string, type: "document" | "publisher" | "collection" | "attachment") {
    let subscription = this.subscriptionResource.subscriptionEndpoint(id, type, 'create');

    if (this.isSubscribed(id)) {
      subscription = this.subscriptionResource.subscriptionEndpoint(id, type, 'remove');
    }

    let updateSubscription: null|Observable<any> = null;
    switch (type) {
      case "attachment":
        updateSubscription = this.getSubscriptionsAttachments();
        break;
      case "collection":
        updateSubscription = this.getSubscriptionsCollections();
        break;
      case "document":
        updateSubscription = this.getSubscriptionsDocuments();
        break;
      case "publisher":
        updateSubscription = this.getSubscriptionsPublishers();
        break;
    }
    subscription.subscribe(() => updateSubscription ? updateSubscription.subscribe(): null);
  }

  public createSubscriptionPublisher(id: string): Observable<boolean> {
    return this.subscriptionResource.subscriptionEndpoint(id, 'publisher', 'create').pipe(
      tap(() => this.getSubscriptionsPublishers().subscribe())
    );
  }

  public createSubscriptionCollection(id: string): Observable<boolean> {
    return this.subscriptionResource.subscriptionEndpoint(id, 'collection', 'create');
  }

  public removeSubscriptionCollection(id: string): Observable<boolean> {
    return this.subscriptionResource.subscriptionEndpoint(id, 'collection', 'remove');
  }

  public getSubscriptionsDocuments(): Observable<SubscriptionDocument[]> {
    return this.subscriptionResource.getSubscriptionsDocuments().pipe(
      tap(documents => this.documents.set(documents)),
    );
  }

  public getSubscriptionsCollections(): Observable<SubscriptionCollection[]> {
    return this.subscriptionResource.getSubscriptionsCollections().pipe(
      tap(collections => this.collections.set(collections)),
    );
  }

  public getSubscriptionsPublishers(): Observable<SubscriptionPublisher[]> {
    return this.subscriptionResource.getSubscriptionsPublishers().pipe(
      tap(publishers => this.publishers.set(publishers)),
    );
  }

  public getSubscriptionsAttachments(): Observable<SubscriptionAttachment[]> {
    return this.subscriptionResource.getSubscriptionsAttachment().pipe(
      tap(attachments => this.attachments.set(attachments)),
    );
  }


  toggle(id: string, type: "document" | "collection" | "publisher" | "attachment") {
    this.toggleSubscription(id, type);
  }
}
