import {Component, inject, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FeatherIconModule} from "../../../core/feather-icon/feather-icon.module";
import {ITreeOptions, ITreeState, TreeComponent, TreeModule, TreeNode} from "@ali-hm/angular-tree-component";
import {ReplaySubject, tap, timer} from "rxjs";
import {ITreeNode} from "@ali-hm/angular-tree-component/lib/defs/api";
import {CollectionService} from "../../../core/services/collection.service";
import {CollectionListItem} from "../../../core/models/document.model";
import {CoreModule} from "../../../core/core.module";

@Component({
  selector: 'app-collection-selector',
  standalone: true,
  imports: [CommonModule, FeatherIconModule, TreeModule, CoreModule],
  template: `
    <div class="modal-header">
      <h5 class="modal-title">
        {{ singleSelect ? 'Vælg en samling' : 'Vælg samlinger' }}
      </h5>
      <button type="button" class="btn-close" (click)="modal.close()" aria-label="Luk"></button>
    </div>
    <div class="modal-body">
      <input type="search" #filter class="form-control mb-2" placeholder="Filtrér i titel og udgiver"
             appAutofocus
             autocomplete="one-time-code"
             (input)="search(filter.value)">
      <div class="tree-view-scroll">
        <tree-root [nodes]="treeData" [options]="options" [ngClass]="'tree'"
                   [(state)]="state"
                   #tree
                   (select)="onSelect($event)"
                   (deselect)="onDeselect($event)"
                   (activate)="onActivateNode($event)">
          <ng-template #treeNodeTemplate let-node let-index="index">
            <div class="tree-node-content">
              <ng-container>
                  <div class="col-auto ps-1 py-0">
                    {{ node.data.title }}
                    <div class="text-muted">{{ node.data.publisher }}</div>
                  </div>
              </ng-container>
            </div>
          </ng-template>
        </tree-root>
      </div>
    </div>
    <div class="modal-footer">
      <div class="float-start me-auto" *ngIf="singleSelect">
        Valgt samling: {{ getSingleSelectedTitle() }}
      </div>
      <button type="button" class="float-end btn btn-outline-secondary" (click)="closeWithNoCollection()"
              *ngIf="allowEmpty">Intet valg
      </button>
      <button type="button" class="float-end btn btn-secondary" (click)="closeWithChosen()"
              [disabled]="collections.length === 0">Vælg
      </button>
    </div>
  `,
  styles: [`
    .modal-body {
      max-height: calc(100vh - 200px);
      overflow-y: auto;
    }

    .tree-view-scroll {
      overflow-y: auto;
      height: calc(100vh - 360px);
    }

    @media (min-width: 992px) {
      .tree-view-scroll {
        height: calc(100vh - 360px);
      }
    }
  `,
  ]
})
export class CollectionSelectorComponent {
  modal = inject(NgbActiveModal);
  private collectionService = inject(CollectionService);
  state: ITreeState = {};
  public collections: CollectionListItem[] = [];
  public singleSelect = false;
  public filterPublisherId = '';
  public allowEmpty = false;
  filterNodesText$ = new ReplaySubject<string>();
  @ViewChild('tree') tree!: TreeComponent;

  ngOnInit(): void {
    this.modal.update({size: 'lg'});
    if (this.collections) {
      // @ts-ignore
      this.state.selectedLeafNodeIds = this.collections.reduce((acc, id) => ({...acc, [id]: true}), {});
    }
    this.collectionService.getCollections(this.filterPublisherId).pipe(
      tap((collections) => this.treeData = this.buildTree(collections)),
    ).subscribe();

    if (this.singleSelect) {
      this.options.useCheckbox = false;
    }

    this.handleFilterInput();
  }

  private handleFilterInput() {
    this.filterNodesText$.subscribe((value) => {
      setTimeout(() => {
        this.tree.treeModel.filterNodes((node: TreeNode) => {
          // split search string into words and search for each word in publisher and titles
          const words = value.split(' ');
          return words.every(word => {
            return simpleSearch(word, node.data.title) || simpleSearch(word, node.data.publisher);
          });
        });
      }, 0);
    });
  }

  close(): void {
    this.modal.close();
  }

  options: ITreeOptions = {
    allowDrag: (node: any) => false,
    allowDrop: (node: any) => false,
    allowDragoverStyling: false,
    levelPadding: 10,
    useVirtualScroll: true,
    animateExpand: true,
    idField: 'id',
    displayField: 'title',
    childrenField: 'children',
    isExpandedField: 'isExpanded',
    scrollOnActivate: true,
    useCheckbox: true,
    animateSpeed: 30,
    animateAcceleration: 1.2,
    useTriState: false,
    scrollContainer: document.documentElement, // HTML
  };
  treeData: Partial<ITreeNode>[] = [];

  onActivateNode(event: { node: TreeNode }): void {
    if (event.node.hasChildren) {
      event.node.toggleExpanded()
    }
    if (this.singleSelect) {
      // make sure change detection will be triggered
      timer(0).subscribe(() => {
        this.collections = [event.node.data.data.collection];
      });
    }

  }

  private buildTree(collections: CollectionListItem[]): Partial<ITreeNode>[] {
    const hashTable = Object.create(null);
    collections.forEach(collection => {
      hashTable[collection.id] = {
        ...collection,
        children: [],
        data: {
          collection: collection
        }
      }
    });

    const dataTree: {
      id: string,
      title: string,
      publisher: string,
      children: any[]
    }[] = [];

    collections.forEach(aData => {
      dataTree.push(hashTable[aData.id])
    });

    // sort by publisher, then by name
    dataTree.sort((a, b) => {
      if (a.publisher === b.publisher) {
        return a.title.localeCompare(b.title);
      }
      return a.publisher.localeCompare(b.publisher);
    });

    return dataTree as Partial<ITreeNode>[];
  }


  getSingleSelectedTitle() {
    return this.collections[0]?.title ?? '';
  }

  onSelect(event: { node: TreeNode }) {
    this.collections.push(event.node.data.collection);
  }

  onDeselect(event: { node: TreeNode }) {
    this.collections = this.collections.filter(collection => collection.id !== event.node.id);
  }

  closeWithChosen() {
    return this.modal.close(this.collections);
  }

  closeWithNoCollection() {
    return this.modal.close([]);
  }

  search(value: string) {
    this.filterNodesText$.next(value);
  }
}

function simpleSearch(needle: string, haystack: string) {
  return haystack.toLowerCase().includes(needle.toLowerCase());

}
