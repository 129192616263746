import {Injectable, signal} from '@angular/core';
import {debounceTime, fromEvent} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AppUtils {
  isMobile = signal<boolean>(false);
  isScreenLg = signal<boolean>(false);


  constructor() {
    fromEvent(window, 'resize')
      .pipe(debounceTime(20))
      .subscribe(() => this.checkIfMobileDevice());

    this.checkIfMobileDevice();
  }

  private checkIfMobileDevice() {
    if (window.innerWidth <= 768) {
      this.isMobile.set(true);
    } else {
      this.isMobile.set(false);
    }
    if (window.innerWidth >= 992) {
      this.isScreenLg.set(true);
    } else {
      this.isScreenLg.set(false);
    }
  }
}
