import {Directive, ElementRef, AfterViewInit, inject} from '@angular/core';
import {AppUtils} from "../utilities/app-utils";

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {
  private appUtils = inject(AppUtils);
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    if (this.appUtils.isMobile()) {
      // do not autofocus on mobile as it will pop up the keyboard
      return;
    }
    this.el.nativeElement.focus();
  }
}
