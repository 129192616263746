import { Component, OnInit } from '@angular/core';
import {CoreConfig} from "../../../core/core.config";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
      public coreConfig: CoreConfig,
  ) { }

  ngOnInit(): void {
  }

}
