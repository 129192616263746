import {Component, forwardRef, inject} from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FeatherIconModule} from "../../../core/feather-icon/feather-icon.module";
import {ITreeOptions, ITreeState, TreeModule, TreeNode} from "@ali-hm/angular-tree-component";
import {tap, timer} from "rxjs";
import {OrganisationService} from "../../../core/services/organisation/organisation.service";
import {Organisation} from "../../../core/models/organisation.model";
import {ITreeNode} from "@ali-hm/angular-tree-component/lib/defs/api";
import {OrganisationNameComponent} from "../organisation-name/organisation-name.component";

@Component({
  selector: 'app-organisation-selector',
  standalone: true,
  imports: [CommonModule, FeatherIconModule, TreeModule,
    forwardRef(() => OrganisationNameComponent)
  ],
  template: `
      <div class="modal-header">
        <h5 class="modal-title">
          {{ singleSelect ? 'Vælg en organisation' : 'Vælg organisationer' }}
        </h5>
        <button type="button" class="btn-close" (click)="modal.close()" aria-label="Luk"></button>
      </div>
      <div class="modal-body">
        <tree-root [nodes]="organisationTree" [options]="options" [ngClass]="'tree'"
                   [(state)]="state"
                   (select)="onSelect($event)"
                   (deselect)="onDeselect($event)"
                   (activate)="onActivateNode($event)">
          <ng-template #treeNodeTemplate let-node let-index="index">
            <div class="tree-node-content">
              <ng-container>
                <i *ngIf="node.isExpanded" class="icon-folder-open me-1"></i>
                <i *ngIf="!node.isExpanded" class="icon-folder-closed me-1"></i>
                <span>{{node.data.name}}</span>
              </ng-container>
            </div>
          </ng-template>
        </tree-root>
      </div>
      <div class="modal-footer">
        <div class="float-start me-auto" *ngIf="singleSelect">
          Valgt organisation: <app-organisation-name [id]="getSingleSelected()" [showPath]="true"></app-organisation-name>
        </div>
        <button type="button" class="float-end btn btn-outline-secondary" (click)="closeWithNoCollection()" *ngIf="allowEmpty">Intet valg</button>
        <button type="button" class="float-end btn btn-secondary" (click)="closeWithChosen()">Vælg</button>
      </div>
  `,
  styles: [
      '.modal-body { max-height: calc(100vh - 200px); overflow-y: auto; }',
  ]
})
export class OrganisationSelectorComponent {
  modal = inject(NgbActiveModal);
  private organisationService = inject(OrganisationService);
  state: ITreeState = {};
  public selectedIds: string[] = [];
  public singleSelect = false;
  public selectPublishers = false;
  public allowEmpty = false;

  ngOnInit(): void {
    this.modal.update({size: 'lg'});
    if (this.selectedIds) {
      this.state.selectedLeafNodeIds = this.selectedIds.reduce((acc, id) => ({...acc, [id]: true}), {});
    }
    let orgSource = this.organisationService.organisations$;
    if (this.selectPublishers) {
      orgSource = this.organisationService.publishers$;
    }

    orgSource.subscribe(orgs => this.organisationTree = this.buildTree(orgs));

    if (this.singleSelect) {
      this.options.useCheckbox = false;
    }
  }

  close(): void {
    this.modal.close();
  }

  options: ITreeOptions = {
    allowDrag: (node: any) => false,
    allowDrop: (node: any) => false,
    allowDragoverStyling: false,
    levelPadding: 10,
    // useVirtualScroll: true,
    animateExpand: true,
    idField: 'id',
    displayField: 'name',
    childrenField: 'children',
    isExpandedField: 'isExpanded',
    scrollOnActivate: true,
    useCheckbox:  true,
    animateSpeed: 30,
    animateAcceleration: 1.2,
    useTriState: false,
    scrollContainer: document.documentElement, // HTML
  };
  organisationTree: Partial<ITreeNode>[] = [];

  onActivateNode(event: {node: TreeNode}): void {
    if (event.node.hasChildren) {
      event.node.toggleExpanded()
    }
    if (this.singleSelect) {
      // make sure change detection will be triggered
      timer(0).subscribe(() => {
        this.selectedIds = [event.node.id];
      });
    }

  }

  private buildTree(orgs: Organisation[]): Partial<ITreeNode>[] {
    const hashTable = Object.create(null);
    orgs.forEach(org => {hashTable[org.id] = {...org, children: []}});
    const dataTree: { id: string, name: string, children: any[] }[] = [];
    orgs.forEach(aData => {
      if(aData.parentId && hashTable[aData.parentId]?.children) {
        hashTable[aData.parentId].children.push(hashTable[aData.id])
        return;
      }

      dataTree.push(hashTable[aData.id])
    });

    // sort tree
    dataTree.sort((a, b) => a.name.localeCompare(b.name));
    dataTree.forEach(node => node.children.sort((a, b) => a.name.localeCompare(b.name)));

    return dataTree as Partial<ITreeNode>[];
  }

  closeWithChosen() {
    return this.modal.close(this.selectedIds);
  }

  getSingleSelected(): string {
    return this.selectedIds[0] ?? '';
  }

  onSelect(event: {node: TreeNode}) {
    this.selectedIds.push(event.node.id);
  }

  onDeselect(event: {node: TreeNode}) {
    this.selectedIds = this.selectedIds.filter(id => id !== event.node.id);
  }

  closeWithNoCollection() {
    return this.modal.close([]);
  }
}
