import {HttpErrorResponse} from '@angular/common/http';
import Swal, {SweetAlertOptions} from 'sweetalert2';
import {from} from 'rxjs';

export class ErrorNotificationService {
    constructor() {
    }

    static defaultSwalConfig: SweetAlertOptions = {
        position: 'bottom-right',
        backdrop: false,
        customClass: {
            popup: 'swal-error-alert'
        },
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
    };


    public static notify(error: HttpErrorResponse): void {
        switch (error.status) {
            case 401:
                this.notifyUnauthorized();
                break;
            case 403:
                this.notifyForbidden();
                break;
            case 404:
              this.notFound(error);
              break;
            case 400:
            case 500:
                this.notifyServerError(error);
                break;
            case 422:
                break; // ignore Unprocessable Entity
            default:
                this.notifyUnknown(error);
        }
    }

    private static notifyUnauthorized(): void {
        from(Swal.fire({
            ...this.defaultSwalConfig,
            icon: 'warning',
            title: '<h3>Din session er udløbet, og du vil nu blive omredigeret til login-siden</h3>',
        })).subscribe(
            (res) => {
                window.location.href = '/';
            }
        );
    }

    private static notifyForbidden(): void {
        Swal.fire({
            ...this.defaultSwalConfig,
            icon: 'warning',
            title: '<h3>Du er ikke autoriseret til at se dette</h3>',
        });
    }

    private static notifyServerError(error: HttpErrorResponse): void {
        Swal.fire({
            ...this.defaultSwalConfig,
            icon: 'error',
            title: '<h3>Der er sket en serverfejl, prøv igen senere</h3>',
            html: `<pre>${error.message}</pre>`,
            timer: 10000,
            showConfirmButton: true,
        });
    }

    private static notifyUnknown(error: HttpErrorResponse): void {
        console.log(error);
        Swal.fire({
            ...this.defaultSwalConfig,
            icon: 'error',
            title: '<h3>Der er sket en fejl, prøv igen senere</h3>',
            html: `<pre>${error.message}</pre>`,
            timer: 10000,
            showConfirmButton: true,
        });
    }

  private static notFound(error: HttpErrorResponse) {
    Swal.fire({
      ...this.defaultSwalConfig,
      icon: 'error',
      title: '<h3>Siden ikke fundet</h3>',
      //html: `<pre>${error.message}</pre>`,
      timer: 10000,
      showConfirmButton: true,
    });
  }
}
