<nav class="navbar">
  <a href="" class="sidebar-toggler" (click)="toggleSidebar($event)">
    <i class="feather icon-menu" appFeatherIcon [attr.data-feather]="'menu'"></i>
  </a>
  <div class="navbar-content">
    <!--<form class="search-form">
      <div class="input-group">
        <div class="input-group-text">
          <i class="feather icon-search" appFeatherIcon [attr.data-feather]="'search'"></i>
        </div>
        <input type="text" class="form-control" id="navbarForm" placeholder="Søg her">
      </div>
    </form>-->
    <ng-container *ngIf="coreConfig.dominoConfig.whitelabel.topbarannouncement">
      <div class="my-auto">
          <a class="announcement"
             (click)="showAnnouncement()"
                [style.color]="'#' + coreConfig.dominoConfig.whitelabel.topbarannouncementcolor">
            {{  coreConfig.dominoConfig.whitelabel.topbarannouncement }}
          </a>
      </div>
    </ng-container>
    <ul class="navbar-nav">

      <li class="nav-item" *ngIf=" ! profileStateService.isLoggedIn()">
        <a routerLink="/auth/login" class="text-nowrap">
          <span style="vertical-align: text-bottom">Log ind</span>
          <i class="mdi mdi-login fs-2 ms-2"></i>
        </a>
      </li>
      <ng-container *ngIf="profileStateService.isLoggedIn()">
        <li class="nav-item nav-profile" ngbDropdown>
          <a class="nav-link" ngbDropdownToggle id="profileDropdown">
            <span class="d-none d-md-inline" style="vertical-align: text-bottom">
              {{ this.profileStateService.profile()?.firstname }} {{ this.profileStateService.profile()?.lastname }}
            </span>
            <i class="icon-user fs-2 ms-2"></i>
          </a>
          <div ngbDropdownMenu class="px-0" aria-labelledby="profileDropdown">
            <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
              <div class="text-center">
                <p class="tx-16 fw-bolder">{{ this.profileStateService.profile()?.firstname }} {{ this.profileStateService.profile()?.lastname }}</p>
                <p class="tx-13 text-muted mt-3">
                  <app-organisation-name [id]="this.profileStateService.profile()?.levelDefault1" class="d-block"></app-organisation-name>
                  <app-organisation-name *ngIf="this.profileStateService.profile()?.levelDefault2" [id]="this.profileStateService.profile()?.levelDefault"></app-organisation-name>
                </p>
              </div>
            </div>
            <ul class="list-unstyled p-1">
              <li class="dropdown-item py-2">
                <a (click)="openProfilModal()" class="d-flex text-body ms-0">
                  <i class="icon-user me-2"></i>
                  <span>Min profil</span>
                </a>
              </li>
              <li class="dropdown-item py-2">
                <a href="" (click)="onLogout($event)" class="d-flex text-body ms-0">
                  <i class="feather icon-log-out me-2 icon-md"></i>
                  <span>Log ud</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</nav>
