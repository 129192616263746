import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CoreConfig} from '../core.config';
import {map} from "rxjs/operators";
import {
  DominoArrayResponse,
  DominoAttachmentListItem, DominoFavorite,
  DominoResponse, DominoSubscriptionAttachment, DominoSubscriptionCollection,
  DominoSubscriptionDocument, DominoSubscriptionPublisher
} from "../models/domino-response.model";
import {Attachment} from "../models/attachment.model";
import {Observable} from "rxjs";
import {
  SubscriptionAttachment,
  SubscriptionCollection,
  SubscriptionDocument,
  SubscriptionPublisher
} from "../models/document.model";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionResource {
  public httpClient = inject(HttpClient);
  public coreConfig = inject(CoreConfig);

  getSubscriptionsDocuments(): Observable<SubscriptionDocument[]> {
    return this.getSubscriptions<DominoSubscriptionDocument>('document').pipe(
      map(subscriptions => subscriptions.map(subscription => {
        const length = subscription.publisherid?.length ?? 0;
        const level = length === 4 ? 1 : length === 6 ? 2 : length === 8 ? 3 : 0;

        return {
          id: subscription.docid,
          title: subscription.title,
          level: level,
          publisher: subscription.publisherdisplay,
          publisherId: subscription.publisherid,
          type: subscription.plejelaegelig
        } as SubscriptionDocument;
      })),
    );
  }

  getSubscriptionsCollections(): Observable<SubscriptionCollection[]> {
    return this.getSubscriptions<DominoSubscriptionCollection>('collection').pipe(
      map(subscriptions => subscriptions.map(subscription => {
        const length = subscription.publisherid?.length ?? 0;
        const level = length === 4 ? 1 : length === 6 ? 2 : length === 8 ? 3 : 0;

        return {
          id: subscription.docid,
          title: subscription.title,
          level: level,
          publisher: subscription.publisherdisplay,
          publisherId: subscription.publisherid,
        } as SubscriptionCollection;
      })),
    );
  }

  getSubscriptionsPublishers(): Observable<SubscriptionPublisher[]> {
    return this.getSubscriptions<DominoSubscriptionPublisher>('publisher').pipe(
      map(subscriptions => subscriptions.map(subscription => {
        const length = subscription.publisherid?.length ?? 0;
        const level = length === 4 ? 1 : length === 6 ? 2 : length === 8 ? 3 : 0;

        return {
          level: level,
          publisher: subscription.publisherdisplay,
          id: subscription.publisherid,
        } as SubscriptionPublisher;
      })),
    );
  }

  getSubscriptionsAttachment(): Observable<SubscriptionAttachment[]> {
    return this.getSubscriptions<DominoSubscriptionAttachment>('attachment').pipe(
      map(subscriptions => subscriptions.map(subscription => {
        const length = subscription.publisherid?.length ?? 0;
        const level = length === 4 ? 1 : length === 6 ? 2 : length === 8 ? 3 : 0;

        return {
          id: subscription.docid,
          title: subscription.title,
          level: level,
          publisher: subscription.publisherdisplay,
          publisherId: subscription.publisherid,
          link: this.coreConfig.api.baseUrl
            + "/"
            + this.coreConfig.dominoConfig.mediadatabase
            + "/vUpload/"
            + subscription.docid,
        } as SubscriptionAttachment;
      })),
    );
  }

  subscriptionEndpoint(id: string, type: string, action: string) {
    return this.httpClient.get<DominoResponse<any>>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.subscriptionDatabase}/updateUserSubscriptions?OpenAgent&doctype=${type}&action=${action}&id=${id}`, {
      withCredentials: true
    }).pipe(
      map(response => {
        if ( ! response.success) {
          throw new Error('API Error: ' + (response as any)?.msg);
        }
        return response.success;
      }),
    )
  }

  getSubscriptions<T>(type: 'document'|'collection'|'publisher'|'attachment'): Observable<T[]> {
    return this.httpClient.get<DominoResponse<T[]>>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.dominoConfig.subscriptionDatabase}/GetSubscriptions?OpenAgent&action=${type}`, {
      withCredentials: true
    }).pipe(
      map(response => response.data),
    )
  }


}
