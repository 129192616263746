import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Document, DocumentComment, DocumentSection} from '../../models/document.model';
import {DocumentResource} from '../../resources/document-resource';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  documentResource = inject(DocumentResource);

  public getDocumentById(id: string, level: string): Observable<Document> {
    return this.documentResource.getDocumentById(id, level);
  }

  public sendReadReceipt(id: string) {
    return this.documentResource.sendReadReceipt(id);
  }

  public getComments(id: string, dbfilepath: string): Observable<DocumentComment[]> {
    return this.documentResource.getComments(id, dbfilepath);
  }

  public addComment(document: Document, comment: string) {
    return this.documentResource.addComment(document.id, document.dbFilePath, comment);
  }

  savePrivateNote(document: Document, section: DocumentSection, content: string): Observable<boolean> {
    return this.documentResource.savePrivateNote(document.id, section, content);
  }

  getHelpDocument() {
    return this.documentResource.getHelpDocument();
  }

  getOperationInformation() {
    return this.documentResource.getOperationInformation();
  }

  getDbPath(id: string) {
    return this.documentResource.getDbPath(id);
  }

  getArchivedDocument(documentID: string, dbPath: string, localAdditionOrganisationId: string) {
    return this.documentResource.getArchivedDocument(documentID, dbPath, localAdditionOrganisationId);
  }
}
