import {AuthConfig} from './models/auth-config.model';
import {DominoConfig} from "./models/domino-config.model";

export class CoreConfig {
  public auth: AuthConfig;

  public constructor() {
    this.auth = {
      clientId: '',
      clientSecret: '',
      authorizeUrl: '',
      baseUrl: '',
      tokenUrl: '',
      scopes: [],
      authorizedUrls: [],
      defaultLogin: {
        email: '',
        password: ''
      }
    };
  }

  public appId = '';
  public version = '';
  public webBaseUrl = '';
  public api = {
    baseUrl: '',
    appConfigUrl: '',
  };

  public featureFlags: {[key: string]: string} = {
    documentPropertiesLayout: 'edok',
  }

  public dominoConfig: DominoConfig;

  public setDominoConfig(dominoConfig: DominoConfig) {
    this.dominoConfig = dominoConfig;
  }

  public static fromConfig(config: any): CoreConfig {
    const newConfig = new CoreConfig();

    newConfig.appId = config.appId || newConfig.appId;
    newConfig.api = config.api || newConfig.api;
    newConfig.webBaseUrl = config.webBaseUrl || newConfig.webBaseUrl;

    if (config.featureFlags) {
      // Merge feature flags
      newConfig.featureFlags = {...newConfig.featureFlags, ...config.featureFlags};
    }

    if (config.auth) {
      const {auth} = config;

      newConfig.auth.clientId = auth.clientId || newConfig.auth.clientId;
      newConfig.auth.clientSecret = auth.clientSecret || newConfig.auth.clientSecret;
      newConfig.auth.authorizeUrl = auth.authorizeUrl || newConfig.auth.authorizeUrl;
      newConfig.auth.baseUrl = auth.baseUrl || newConfig.auth.baseUrl;
      newConfig.auth.tokenUrl = auth.tokenUrl || newConfig.auth.tokenUrl;
      newConfig.auth.scopes = auth.scopes || newConfig.auth.scopes;
      newConfig.auth.authorizedUrls = auth.authorizedUrls || newConfig.auth.authorizedUrls;

      newConfig.auth.defaultLogin.email = auth.defaultLogin.email || newConfig.auth.defaultLogin.email;
      newConfig.auth.defaultLogin.password = auth.defaultLogin.password || newConfig.auth.defaultLogin.password;
    }

    return newConfig;
  }

  public setVersion(version: string) {
    this.version = version;
  }
}
