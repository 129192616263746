import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {of} from 'rxjs';
import {delay} from 'rxjs/operators';
import {LoadingState} from './loading-state.enum';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit, OnChanges {

    @Input()
    state: LoadingState = LoadingState.NONE;

    loadingStates = LoadingState;

    show = true

    private timeout = 2000;

    ngOnChanges(changes: SimpleChanges) {
        this.show = true;
        const newState = changes.state.currentValue as unknown as LoadingState;
        if (newState == LoadingState.SUCCESS || newState == LoadingState.FAILURE) {
            of(changes.state).pipe(delay(this.timeout)).subscribe(() => this.show = false);
        }

        // this.doSomething(changes.categoryId.currentValue);
        // You can also use categoryId.previousValue and
        // categoryId.firstChange for comparing old and new values

    }

    constructor() {
    }

    ngOnInit(): void {
    }

}
