import {computed, inject, Injectable, signal} from '@angular/core';
import {SubscriptionResource} from "../resources/subscription-resource";
import {merge, Observable, of, tap} from "rxjs";
import {
  Favorite,
  SubscriptionAttachment,
  SubscriptionCollection,
  SubscriptionDocument,
  SubscriptionPublisher
} from "../models/document.model";
import {FavoriteResource} from "../resources/favorite-resource";

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {
  private favoriteResource = inject(FavoriteResource);
  public favorites = signal<Favorite[]>([]);
  public collections = computed(() => this.favorites().filter(favorite => favorite.doctype === 'collection'));
  public documents = computed(() => this.favorites().filter(favorite => favorite.doctype === 'document'));
  private isInitiated = false;

  public initialLoad(force: boolean = false) {
    if (this.isInitiated && !force) {
      return;
    }
    merge(
      this.getFavorites(),
    ).subscribe();

    this.isInitiated = true;
  }
  public isFavorite(id: string): boolean {
    return this.favorites().some(document => document.id === id);
  }

  private toggleFavorite(id: string, type: "document" | "collection") {
    let subscription;

    if (this.isFavorite(id)) {
      this.favorites.set(this.favorites().filter(document => document.id !== id));
      subscription = this.favoriteResource.endpoint(id, type, 'remove');
    } else {
      this.favorites.set([...this.favorites(), {id, type} as Favorite]);
      subscription = this.favoriteResource.endpoint(id, type, 'create');
    }
    subscription.subscribe(() => this.getFavorites().subscribe());
  }

  public getFavorites(): Observable<Favorite[]> {
    return this.favoriteResource.getFavorites().pipe(
      tap(favorites => this.favorites.set(favorites)),
    );
  }

  toggle(id: string, type: "document" | "collection") {
    this.toggleFavorite(id, type);
  }
}
