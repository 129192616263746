import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {interval, Observable, tap} from 'rxjs';
import {filter, map, switchMap} from "rxjs/operators";
import {CoreConfig} from "../core.config";
import {ProfileService} from "./profile.service";
import {SubscriptionService} from "./subscription.service";
import {FavoriteService} from "./favorite.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private profileService: ProfileService,
    private subscriptionService: SubscriptionService,
    private favoriteService: FavoriteService,
    public coreConfig: CoreConfig) {
  }

  login(username: string, password: string): Observable<boolean> {
    const formData = new FormData();
    formData.append('Username', username);
    formData.append('Password', password);

    return this.http.post(`${this.coreConfig.api.baseUrl}/names.nsf?Login`, formData, {
      withCredentials: true,
      responseType: 'text',
    }).pipe(
      switchMap(() => this.updateLoginStatus()),
      tap(() => this.loadUserData(true)),
    );
  }

  logout(): Observable<any> {
    return this.http.get(`${this.coreConfig.api.baseUrl}/names.nsf?logout`, {
      withCredentials: true,
      responseType: 'text',
    }).pipe(
      switchMap(() => this.updateLoginStatus()),
    );
  }

  public updateLoginStatus(): Observable<boolean> {
    return this.profileService.getProfileAndUpdateState().pipe(
      map(userProfile => userProfile !== null),
    )
  }


  private loadUserData(force: boolean = false) {
    // make sure its loaded
    this.subscriptionService.initialLoad(force);
    this.favoriteService.initialLoad(force);
  }

  pollLoginStatus() {
    // initial load
    this.updateLoginStatus().subscribe();
    this.loadUserData();

    // poll every 2 minutes
    interval(1000 * 60 * 2).pipe(
      filter(() => this.profileService.profileStateService.isLoggedIn()),
      switchMap(() => this.updateLoginStatus())
    ).subscribe();
  }
}
