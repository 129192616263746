import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private titlePostFix = '';
  private routeTitle = '';

  constructor(
      private ngTitleService: Title,
      private router: Router,
      private activatedRoute: ActivatedRoute
  ) {}

  public init(postFix = ""): void {
    this.titlePostFix = postFix;
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        mergeMap(route => route.data)
    ).subscribe(data => {
      if (data.title) {
        this.routeTitle = data.title;
        this.ngTitleService.setTitle(`${data.title} ${this.titlePostFix}`);
      }
    });
  }

  public updateTitle(documentTitle: string): void {
    this.ngTitleService.setTitle(`${documentTitle} ${this.titlePostFix}`);
  }

  public setFullTitle(title: string): void {
    this.ngTitleService.setTitle(title);
  }

  public prependRouteTitle(prepend: string): void {
    this.ngTitleService.setTitle(`${prepend} - ${this.routeTitle} ${this.titlePostFix}`);
  }
}
