import {inject, Injectable} from '@angular/core';
import {MenuItem} from "../../views/layout/sidebar/menu.model";
import {CoreConfig} from "../core.config";
import {Profile} from "../models/profile.model";

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  coreConfig = inject(CoreConfig);


  getMenuItems(profile: Profile | null): MenuItem[] {
    const isAuthenticated = profile !== null;

    let items: MenuItem[] = [
      {
        label: 'Dokumenter',
        iconClass: 'icon-search',
        link: '/documents',
      },
      {
        label: 'Samlinger',
        iconClass: 'icon-binder',
        link: '/collections',
      }];

    if (isAuthenticated) {
      if (this.coreConfig.dominoConfig.usemedialibrary) {
        items.push({
          label: 'Bilag',
          iconClass: 'icon-attach',
          link: '/attachments',
        });
      }

      items.push({
        label: 'Favoritter',
        iconClass: 'icon-star',
        link: '/favorites',
      });
      items.push({
        label: 'Abonnementer',
        iconClass: 'icon-notification',
        link: '/subscriptions',
      });
    }



    if (isAuthenticated) {
      items.push(... this.addRoleBasedMenuItems(profile));
    }

    if (this.coreConfig.dominoConfig.webhelpurl) {
      items.push({
        label: 'Hjælp',
        iconClass: 'icon-inquiry',
        link: '/documents/help',
      });
    }

    if (this.coreConfig.dominoConfig.operationinfopurl) {
      items.push({
        label: 'Driftsinformation',
        iconClass: 'icon-info',
        link: '/documents/operating-information',
      });
    }

    return items;
  }

  private addRoleBasedMenuItems(profile: Profile) {
    const items = [];

    if (this.showMyPage(profile)) {
      items.push({
        label: 'Min side',
        iconClass: 'icon-what-i-do',
        link: 'legacy/my-page',
        isHiddenInMobile: true,
      });
    }

    if (this.showAuthor(profile)) {
      items.push({
        label: 'Forfatter',
        iconClass: 'icon-document-writer',
        link: '/legacy/author',
        isHiddenInMobile: true,
      });
    }

    if (this.showEditor(profile)) {
      items.push({
        label: 'Redaktør',
        iconClass: 'icon-edit-text-file',
        link: '/legacy/editor',
        isHiddenInMobile: true,
      });
    }

    if (this.showReview(profile)) {
      items.push({
        label: 'Høring',
        iconClass: 'icon-document-writer',
        link: '/legacy/review',
        isHiddenInMobile: true,
      });
    }

    if (this.showQreq(profile)) {
      items.push({
        label: 'Qreg',
        iconClass: 'icon-new-copy',
        link: '/legacy/qreg',
        isHiddenInMobile: true,
      });
    }

    if (this.showSetup(profile)) {
      items.push({
        label: 'Admin',
        iconClass: 'icon-settings',
        link: '/legacy/admin',
        isHiddenInMobile: true,
      });
    }

    return items;
  }

  private showMyPage(profile: Profile) {
    return this.coreConfig.dominoConfig.mypageurl
      && this.anyRoleMatch(profile.roles, this.coreConfig.dominoConfig.menuroles.mypage);
  }

  private showAuthor(profile: Profile) {
    return this.coreConfig.dominoConfig.authorurl
      && this.anyRoleMatch(profile.roles, this.coreConfig.dominoConfig.menuroles.author);
  }

  private showEditor(profile: Profile) {
    return this.coreConfig.dominoConfig.editorurl
      && this.anyRoleMatch(profile.roles, this.coreConfig.dominoConfig.menuroles.editor);
  }

  private showReview(profile: Profile) {
    return this.coreConfig.dominoConfig.reviewurl
      && this.anyRoleMatch(profile.roles, this.coreConfig.dominoConfig.menuroles.review);
  }

  private showQreq(profile: Profile) {
    return this.coreConfig.dominoConfig.qregurl
      && this.anyRoleMatch(profile.roles, this.coreConfig.dominoConfig.menuroles.qreg);
  }

  private showSetup(profile: Profile) {
    return this.coreConfig.dominoConfig.setupurl
      && this.anyRoleMatch(profile.roles, this.coreConfig.dominoConfig.menuroles.setup);
  }

  private anyRoleMatch(profileRoles: string[], menuItemRoles: string[]) {
    return menuItemRoles.some(role => profileRoles.includes(role));
  }
}
