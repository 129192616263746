<div class="d-flex align-items-center justify-content-center" style="padding-top: 15%;">
    <div class="row w-100 mx-0 auth-page">
        <div class="col-md-10 col-xl-8 mx-auto d-flex flex-column align-items-center">
            <img src="assets/images/403.svg" class="img-fluid mb-2" alt="404">
            <h1 class="font-weight-bold mb-22 mt-2 tx-80 text-muted">403</h1>
            <h3 class="mb-2">Du har ikke adgang til denne side</h3>
            <h5 class="text-muted mb-3 text-center">Kontakt en administrator hvis dette er en fejl</h5>
        </div>
    </div>
</div>
