import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';

import {LayoutModule} from './views/layout/layout.module';

import {AppComponent} from './app.component';
import {ErrorPageComponent} from './views/pages/error-page/error-page.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CoreModule} from './core/core.module';
import {environment} from '../environments/environment';
import {CoreConfig} from "./core/core.config";
import {firstValueFrom} from "rxjs";
import {DominoConfigService} from "./core/services/domino-config.service";
import {CacheInterceptor} from "./core/utilities/cache-http-intercepter";
import {registerLocaleData} from "@angular/common";

import localeDk from '@angular/common/locales/da';
import localeDkExtra from '@angular/common/locales/extra/da';
import Swal from "sweetalert2";

registerLocaleData(localeDk, 'da-DK', localeDkExtra);


@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,

    CoreModule.withConfig({
      auth: {
        clientId: "environment.auth.clientId",
        clientSecret: "environment.auth.clientSecret",
        baseUrl: "environment.auth.baseUrl",
        scopes: ['*'],
        authorizedUrls: [
          environment.api.baseUrl,
        ],
        defaultLogin: {
          email: environment.auth.defaultLogin.email,
          password: environment.auth.defaultLogin.password,
        }
      },
      featureFlags: environment.featureFlags,
      appId: environment.appId,
      apiHost: environment.api.baseUrl,
      api: {
        baseUrl: environment.api.baseUrl,
        appConfigUrl: environment.api.appConfigUrl,
      },
      webBaseUrl: environment.webBaseUrl,
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadAppConfig,
      multi: true,
      deps: [
        DominoConfigService,
        CoreConfig,
      ]
    },
    {provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'da-DK'}

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function loadAppConfig(config: DominoConfigService, coreConfig: CoreConfig) {
  return () => firstValueFrom(config.getAppConfig()).then((dominoConfig) => {
    coreConfig.setDominoConfig(dominoConfig);
  }).catch((error) => {
    Swal.fire({
      title: 'Serverfejl',
      text: 'Der er sket en fejl under indlæsning af konfigurationen fra serveren. Prøv at genindlæse siden.',
      icon: 'error',
    });
    console.error(error);
    //throw error;
  });
}
