import {MonoTypeOperatorFunction, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ErrorNotificationService} from './error-notification.service';

export function handleHttpError<T>(): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>): Observable<T> => {
        return source.pipe(
            tap(
                () => {
                  // do nothing on next/success
                },
                (error) => {
                    ErrorNotificationService.notify(error);
                }
            )
        );
    };
}



