import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-user-unauthorized-page',
    templateUrl: './user-unauthorized-page.component.html',
    styleUrls: ['./user-unauthorized-page.component.scss']
})
export class UserUnauthorizedPageComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
