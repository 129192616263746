import {AfterViewInit, Directive} from '@angular/core';
import * as feather from 'feather-icons';

@Directive({
    selector: '[appFeatherIcon]'
})
export class FeatherIconDirective implements AfterViewInit {

    constructor() {
    }

    ngAfterViewInit(): void {
        // feather icon
        feather.replace();
    }

}
