import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Router, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd, ActivatedRoute} from '@angular/router';
import {Subject} from "rxjs";
import {filter, map, mergeMap} from "rxjs/operators";

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit, OnDestroy {

  isLoading: boolean;
  sidebarToggle$ = new Subject<void>();
  noPadding = false;
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.handleSpinner();
    this.handlePageContentNoPaddingRoutes();
  }

  private handleSpinner() {
    // Spinner for lazyload modules
    this.router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isLoading = false;
      }
    });
  }

  private handlePageContentNoPaddingRoutes() {
    // Check the initial route data
    let currentRoute = this.activatedRoute;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    currentRoute.data.subscribe(data => {
      this.noPadding = data.pageContentNoPadding || false;
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      mergeMap(route => route.data)
    ).subscribe(data => {
      this.noPadding = data.pageContentNoPadding || false;
    });
  }

  sidebarToggled() {
    this.sidebarToggle$.next();
  }
}
