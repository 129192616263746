import {Component, OnInit, OnDestroy, inject} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {Location} from "@angular/common";

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit, OnDestroy {
  private location = inject(Location);
  private route = inject(ActivatedRoute);

  type: any;
  title: any;
  desc: any;
  private sub: Subscription;

  ngOnInit(): void {
    this.type = this.route.snapshot.paramMap.get('type');

    this.sub = this.route.data.subscribe( param => {
      if(param.type) {
        this.type = param.type;
      }
      if(param.title) {
        this.title = param.title;
      }
      if(param.desc) {
        this.desc = param.desc
      }
    });

    switch(this.type) {
      case '404':
        if (!this.title) {
          this.title = 'Side ikke fundet'
        }
        if (!this.desc) {
          this.desc = 'Den side du leder efter findes ikke.'
        }
        break;
      case '500':
        if (!this.title) {
          this.title = 'Serverfejl'
        }
        if (!this.desc) {
          this.desc = 'Der er sket en fejl på serveren.<br>' + 'Vi arbejder på det'
        }
        break;
      default:
        if (!this.type) {
          this.type = 'Ukendt fejl';
        }
        if (!this.title) {
          this.title = 'Der gik noget galt';
        }
        if (!this.desc) {
          this.desc = 'Det ser ud til at noget gik galt.<br>' + 'Vi arbejder på det';
        }
    }
  }

	ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  goback($event: any) {
    if (window.history.length > 2) {
      $event.preventDefault();
      $event.stopPropagation();
      this.location.back();
    }
  }
}
