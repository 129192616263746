import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import * as dayjs from 'dayjs';

@Pipe({name: 'appDate'})
export class AppDatePipe extends DatePipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) locale: string) {
    super(locale);
  }

  // @ts-ignore
  transform(value: any, format = 'mediumDate', timezone?: string, locale?: string): string {
    const dateParsed = dayjs(value);
    if (! dateParsed.isValid()) {
      return '';
    }
    return super.transform(dateParsed.toDate(), format, timezone, locale) ?? "";
  }
}
