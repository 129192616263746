import {Injectable} from '@angular/core';
import {from, Observable, ReplaySubject, tap, zip} from 'rxjs';
import {Organisation} from '../../models/organisation.model';
import {OrganisationResource} from '../../resources/organisation-resource';
import {CollectionListItem} from "../../models/collection.model";
import {
  CollectionSelectorComponent
} from "../../../shared/components/collection-selector/collection-selector.component";
import {filter, map} from "rxjs/operators";
import {
  OrganisationSelectorComponent
} from "../../../shared/components/organication-selector/organisation-selector.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
  private allOrganisations: Organisation[] = [];
  public organisations$ = new ReplaySubject<Organisation[]>(1);
  public publishers$ = new ReplaySubject<Organisation[]>(1);

  constructor(
    private organisationResource: OrganisationResource,
    private modalService: NgbModal,
  ) {
  }

  public loadOrganisations() {

    zip(this.publishers$, this.organisations$).pipe(
      tap(([publishers, organisations]) => {
        const all = [...publishers, ...organisations];
        const ids = all.map(({ id }) => id);
        const noDuplicates = all.filter(({ id }, index) => !ids.includes(id, index + 1));
        this.allOrganisations = noDuplicates;
      })
    ).subscribe();

    this.organisationResource.getOrganisations().pipe(
      tap(organisations => this.organisations$.next(organisations)),
    ).subscribe();

    this.organisationResource.getOrganisationsPublishing().pipe(
      tap(organisations => this.publishers$.next(organisations)),
    ).subscribe();
  }

  public getOrganisationById(id: string) {
    return this.allOrganisations.find((item) => item.id === id);
  }

  selectOrganisation(singleSelect = false, selectedIds: string[] = [], selectPublishers = true) {
    const modal = this.modalService.open(OrganisationSelectorComponent);
    modal.componentInstance.singleSelect = singleSelect;
    modal.componentInstance.selectedIds = selectedIds;
    modal.componentInstance.selectPublishers = selectPublishers;

    return from(modal.result).pipe(
      filter((value) => Array.isArray(value)),
      map((ids: string[]) => ids),
    );
  }
}
